import { useState, useCallback, useEffect } from 'react';

export function useViewport() {
  const [viewport, setViewport] = useState<{
    width: number;
    height: number;
  }>({ width: window.innerWidth, height: 800 });

  const measure = useCallback(() => {
    setViewport({
      width: window.innerWidth,
      // height: window.innerHeight,
      // NOTE: Cannot get parent dimensions from within iframe so we use a
      // constant
      height: 800,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', measure);
    return () => {
      window.removeEventListener('resize', measure);
    };
  }, [measure]);

  return viewport;
}
