import * as React from 'react';
import { css } from '@emotion/core';

export const titleBlock = {
  title: 'Go wild',
  content:
    'Tauch ein in das Abenteuer Deines Lebens: Begib Dich auf Safari, in monumentale Gebirgszüge, in die traumhafteste Wüste – und mit etwas Glück gewinnst Du mit Wikinger Reisen einen Trip ins knallbunte Südafrika.',
};

const ratio = [
  904 / 1068,
  1030 / 1068,
  927 / 1070,
  970 / 1068,
  1028 / 1068,
  1130 / 1082,
  1104 / 1070,
  1106 / 1070,
  942 / 1068,
  1070 / 1068,
];
const width = 600;

// 0,218446602% offset top
// 578,651685393 height

export const tips = [
  {
    title: 'Kapstadt',
    photo: require('./images/Jpgs/01_Kapstadt_result.jpg'),
    photoMobile: require('./images/Mobile/01_Kapstadt_Hi_Res.jpg'),
    decoration: require('./images/Grafiken/Tipps/01_Kapstadt.png'),
    decorationMobile: require('./images/Mobile/Tipps/01_Kapstadt.png'),
    headerCorrection: 150 * ratio[0],
    headerMobile: 55,
    height: width * ratio[0],
    // decorationTop: require('./images/1-upper.png'),
    content:
      'Kapstadt ist so umwerfend! Kapstadt ist so fantastisch gelegen, dass einem beim Blick vom Tafelberg die Kinnlade runterfällt. Und wer Kapstadt und seine Umgebung auf besondere Art entdecken will, der steigt aufs Rad. Also runter vom Tafelberg und hinaus aus der Stadt, vielleicht über den Chapman’s Peak Drive ans Kap. Eine der schönsten und spektakulärsten Küstenstraßen der Welt abradeln. Und als wäre das noch nicht besonders genug, schaust Du dann am Stoney Point den Pinguinen beim Watscheln zu.',
  },

  {
    title: 'Tsitsikamma',
    photo: require('./images/Jpgs/02_Tsitsikamma_result.jpg'),
    photoMobile: require('./images/Mobile/02_Tsitsikamma.jpg'),
    decoration: require('./images/Grafiken/Tipps/02_Tsitsikamma.png'),
    decorationMobile: require('./images/Mobile/Tipps/02_Tsitsikamma.png'),
    headerCorrection: 166,
    headerMobile: 80,
    height: width * ratio[1],
    content:
      '200 Meter tief hat sich der Storms River kurz vor der Mündung ins Gestein eingeschnitten, die Wände der Schlucht zu beiden Seiten der Hängebrücke fast senkrecht. Dass eine Wanderung gleich zu Beginn mitten durch ein Postkartenmotiv führt, ist selten. Aber der Tsitsikamma Park ist so unglaublich schön, dass das kaum auffällt. Urwälder bedecken die Schluchtenhänge der Flüsse, die ins Meer münden, Vögel lärmen im Dickicht und der Ozean murmelt mal leise, mal donnert er ein ohrenbetäubendes Crescendo.',
  },

  {
    title: 'Drakensberge',
    photo: require('./images/Jpgs/03_Drakensberge_result.jpg'),
    photoMobile: require('./images/Mobile/03_Drakensberge.jpg'),
    decoration: require('./images/Grafiken/Tipps/03_Drakensberge.png'),
    decorationMobile: require('./images/Mobile/Tipps/03_Drakensberge.png'),
    headerCorrection: 175,
    headerMobile: 80,
    height: width * ratio[2],
    content:
      'Aus der Vogelperspektive mögen die Drakensberge mit ihren vielen zerklüfteten Kämmen tatsächlich an den Rücken von Drachen erinnern. Zu Fuß sind die Eindrücke aber noch viel überbordender: Schluchten, Hochebenen, Wasserfälle, Wildblumenwiesen, monumental aufragende Felswände. Und unter jedem Überhang und in jeder Höhle die Felsmalereien der San. So alt, so verbindend, als würdest Du in ihr Leben eintauchen können, weil jeder Fels eine Geschichte flüstert.',
  },

  {
    title: 'Garden Route',
    photo: require('./images/Jpgs/04_GardenRoute_result.jpg'),
    photoMobile: require('./images/Mobile/04_GardenRoute.jpg'),
    decoration: require('./images/Grafiken/Tipps/04_GardenRoute.png'),
    decorationMobile: require('./images/Mobile/Tipps/04_Gardenroute.png'),
    headerCorrection: 144,
    headerMobile: 70,
    height: width * ratio[3],
    content:
      'Ein Roadtrip durch Südafrika ist unweigerlich mit dem Namen Garden Route verbunden. Wobei die Route von Kapstadt nach Port Elizabeth weniger mit Gärten als vielmehr mit wahnsinnigen Küstenabschnitten und vielseitigen Parks – Addo Elephant, Tsitsikamma oder Robberg Nature Reserve – aufwartet. Und alle, die das ganz nah und langsam erleben wollen, sollten die Garden Route auf jeden Fall einmal unter die Pedale nehmen. Am entspanntesten mit Rückenwind vom Indischen Ozean.',
  },

  {
    title: 'Nachhaltigkeit',
    photo: require('./images/Jpgs/05_Nachhaltigkeit_result.jpg'),
    photoMobile: require('./images/Mobile/05_Nachhaltigkeit.jpg'),
    decoration: require('./images/Grafiken/Tipps/05_Nachhaltigkeit.png'),
    decorationMobile: require('./images/Mobile/Tipps/05_Nachhaltigkeit.png'),
    headerCorrection: 190,
    headerMobile: 70,
    height: width * ratio[4],
    content:
      'Der Graskop Canyon ist ein ganz und gar grünes Beispiel für Südafrikas nachhaltigen Tourismus. Um die empfindliche Vegetation im Canyon zu schützen, wurde ein Wegenetz aus Holzstegen konstruiert. Überhaupt hat das Land schon vor Jahren ein Fair-Trade-System etabliert, das vor allem kleinere und lokale touristische Anbieter fördert und sichtbarer macht. So werden ausgefallenere Dinge erlebbar und die lokale Community gestärkt – ganz regenbogennationmäßig eben.',
  },

  {
    title: 'Kalahari',
    photo: require('./images/Jpgs/06_Kalahari_result.jpg'),
    photoMobile: require('./images/Mobile/06_Kalahari.jpg'),
    decoration: require('./images/Grafiken/Tipps/06_Kalahari.png'),
    decorationMobile: require('./images/Mobile/Tipps/06_Kalahari.png'),
    headerCorrection: 235,
    headerMobile: 120,
    height: width * ratio[5],
    content:
      'Das wahre Outback Südafrikas liegt im Norden. Im zungenbrecherischen Kgalagadi Transfrontier Park fährt die Kalahari zu gewaltiger Opulenz auf. Sand und Dünen vom Winde in die ausgefallensten Muster verweht, so weit das Auge reicht. Ikonische Antilopenarten sind hier heimisch, und das schönste Rot beim Sonnenuntergang ist sicher auch in der Kalahari erfunden worden. Und nachts singt ganz leise der Wind zum grenzenlosen Sternenhimmel.',
  },

  {
    title: 'Blyde River Canyon',
    photo: require('./images/Jpgs/07_BlydeRiverCanyon_result.jpg'),
    photoMobile: require('./images/Mobile/07_BlydeRiverCanyon.jpg'),
    decoration: require('./images/Grafiken/Tipps/07_BlydeRiverCanyon.png'),
    decorationMobile: require('./images/Mobile/Tipps/07_BlydeRiverCanyon.png'),
    headerCorrection: 260,
    headerMobile: 120,
    height: width * ratio[6],
    content:
      'Willkommen zu der erhabensten Aussicht, die Südafrika zu bieten hat: Three Rondavels – »drei Rundhütten« werden die wie abgehobelt scheinenden Berge genannt. Tief unten der Blyde River, der den drittgrößten Canyon aus dem uralten Gestein gefräst hat. Hier staunst Du kurz oder auch länger und schwebst dann vielleicht sogar ein bisschen – besonders mühelos, wenn du zuvor zu den Bourke’s Luck Potholes oder zum God’s Window gewandert bist.',
  },

  {
    title: 'Buschwandern',
    photo: require('./images/Jpgs/08_Buschwandern_result.jpg'),
    photoMobile: require('./images/Mobile/08_Buschwandern.jpg'),
    decoration: require('./images/Grafiken/Tipps/08_Buschwandern_01.png'),
    decorationMobile: require('./images/Mobile/Tipps/08_Buschwandern.png'),
    headerCorrection: 220,
    headerMobile: 120,
    height: width * ratio[7],
    content:
      'Eine Safari, die ist unvergesslich. Und vom Jeep aus kann sie eigentlich jeder. Zu Fuß durch den Busch und auf Augenhöhe mit den wilden Tieren zu sein ist allerdings eine ganz andere Sache. Alles wird plötzlich intensiver. Jedes Geräusch, jeder Laut wird anders wahrgenommen. Es prickelt an den Nervenenden, als hätte man ganz leicht in eine Steckdose gefasst. In beide Abenteuer kann man sich wunderbar im riesigen Kruger-Nationalpark stürzen.',
  },

  {
    title: 'Wild Coast',
    photo: require('./images/Jpgs/09_WildCoast_result.jpg'),
    photoMobile: require('./images/Mobile/09_WildCoast.jpg'),
    decoration: require('./images/Grafiken/Tipps/09_WildCoast.png'),
    decorationMobile: require('./images/Mobile/Tipps/09_WildCoast.png'),
    headerCorrection: 150,
    headerMobile: 60,
    height: width * ratio[8],
    content:
      'Wild, ungestüm und weit weg von allem, das ist die Wild Coast. Zwischen Durban und East London liegt sie eingeklemmt zwischen dem Indischen Ozean und endlosen grünen Hügelketten. Nelson Mandela stammt aus der Ecke, über seine Heimat sagte er: »Wenn man einen großen Hügel erklommen hat, sieht man erst, dass noch viele Hügel mehr zu erklimmen sind.« Wandern ist das Mittel der Wahl, so lässt sich die grandios zerklüftete Küste am schönsten erleben.',
  },

  {
    title: 'Addo Elephant Park',
    photo: require('./images/Jpgs/10_Addo_Elephant_result.jpg'),
    photoMobile: require('./images/Mobile/10_Addo_Elephant.jpg'),
    decoration: require('./images/Grafiken/Tipps/10_AddoElephantPark.png'),
    decorationMobile: require('./images/Mobile/Tipps/10_AddoElephantPark.png'),
    headerCorrection: 200,
    headerMobile: 100,
    height: width * ratio[9],
    content:
      'Die Erde bebt, Bäume schütteln sich wie von Geisterhand, Staub steigt auf. Plötzlich ein Rüssel im Dickicht und die Bäume weichen zur Seite – und immer mehr Tiere der Elefantenherde kommen zum Vorschein. Dann sind sie um Dich herum und Du mittendrin, ganz gemächlich ziehen sie vorbei, womöglich auf dem Weg zu einem Wasserloch. Noch Minuten nachdem sie verschwunden sind, hüpft dein Herz vor Aufregung.',
  },
];

export const questions = [
  {
    title: 'Wie hoch sind die Canyonwände des Storms River an seiner Mündung? ',
    answers: [
      { title: '100 Meter', value: '100_meter' },
      { title: '200 Meter', value: '200_meter' },
      { title: '300 Meter', value: '300_meter' },
    ],
  },

  {
    title: 'Wo befindet sich die Heimat von Nelson Mandela?',
    answers: [
      { title: 'Hinterland der West Coast', value: 'west_coast' },
      { title: 'Hinterland der Wild Coast', value: 'wild_coast' },
      { title: 'Kalahari-Region', value: 'kalahari' },
    ],
  },

  {
    title: 'Wo findet man die Three Rondavels?',
    answers: [
      { title: 'Entlang der Garden Route', value: 'garden_route' },
      { title: 'Am Blyde River Canyon', value: 'blyde_river_canyon' },
      { title: 'Im Kruger-Nationalpark', value: 'kruger_nationalpark' },
    ],
  },
];

export const infoQuestions = [
  {
    id: 'newsletter_south_africa',
    text: 'Ich möchte den Newsletter von South African Tourism bestellen.',
  },
  {
    id: 'newsletter_wikinger',
    text: 'Ich möchte den Newsletter von Wikinger Reisen bestellen.',
  },
  {
    id: 'newsletter_globetrotter',
    text: 'Ich möchte den Newsletter von Globetrotter Ausrüstung bestellen.',
  },
  {
    id: 'fernreise_aktiv_katalog',
    text: (
      <>
        Ich möchte den{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.wikinger.de/katalogbestellung/?utm_campaign=lp-globetrotter-suedafrika&utm_medium=referral&utm_source=globetrotter&utm_content=suedafrika"
        >
          Fernreisen-Aktiv-Katalog von Wikinger Reisen
        </a>{' '}
        zugeschickt bekommen.
      </>
    ),
  },
];

export const fonts = ['Open+Sans+Condensed:300,700', 'Open+Sans:600,700,800'];

export const globalStyles = css`
  * {
    font-size: 16px;
    font-family: Open Sans;
    font-weight: 600;
    color: #4f4040;
    line-height: 30px;
  }

  @media (max-width: 700px) {
    * {
      font-family: Open Sans Condensed;
    }
  }

  a {
    color: #c35a58;
    text-decoration: none;
    text-decorationmobile: none;
    font-mobile: 1em;

    :hover {
      text-decoration: underline;
      text-decorationmobile: underline;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 10px;
    border: 4px solid #fff;
  }

  body {
    overscroll-behavior: none;
  }

  @media (max-width: 700px) {
    * {
      font-size: 12px;
    }
  }
`;
