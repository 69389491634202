/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { Knockout, PageBlock, TipBlock, TitleContent } from './Blocks';
import { fonts, globalStyles, tips, titleBlock } from './config';
import { ImagePane } from './CoolImage';
import { Sweepstakes } from './Sweepstakes';
import { useGoogleFonts } from './useGoogleFonts';
import { useViewport } from './useViewport';

export function App(props: {}) {
  const fontsLoaded = useGoogleFonts(fonts);
  const viewport = useViewport();

  if (viewport === undefined) return null;

  return (
    <>
      <Global styles={globalStyles} />
      <Page ready={fontsLoaded} height={viewport.height}>
        <PageBlock
          height={viewport.height}
          css={css`
            @media (max-width: 700px) {
              height: 400px;
              min-height: 400px;
            }
          `}
        >
          <ImagePane
            src={require('./images/Jpgs/Header_BG.jpg')}
            center="center bottom"
            css={css`
              @media (max-width: 700px) {
                height: 400px;
                min-height: 400px;
              }
            `}
          >
            <ImagePane
              src={require('./images/header-overlay.png')}
              willItBlend
              css={css`
                @media (max-width: 700px) {
                  height: 400px;
                  min-height: 400px;
                }
              `}
            >
              <SiteHead>
                Traumtrip
                <br />
                Südafrika
              </SiteHead>
            </ImagePane>
          </ImagePane>
        </PageBlock>

        <TitleContent>
          <img
            src={require('./images/header-border.png')}
            style={{
              position: 'absolute',
              bottom: '100%',
              right: 0,
              width: '100vw',
              maxWidth: 1280,
            }}
          />
          <article>
            <div>
              <h2>{titleBlock.title}</h2>

              <p>{titleBlock.content}</p>
            </div>
          </article>
        </TitleContent>

        {tips.map((tip, index) => (
          <Tip key={index} tip={tip} />
        ))}

        <Sweepstakes />

        <img
          src={require('./images/Grafiken/footer/Footer.png')}
          role="presentation"
          width="100%"
          style={{ display: 'block', margin: '60px 0 0' }}
        />
      </Page>
    </>
  );
}

const Page = styled.div<{ ready: boolean; height: number }>`
  visibility: ${props => (props.ready ? 'visible' : 'hidden')};
  position: relative;
  max-width: 1280px;
  min-height: ${props => props.height}px;
  margin: 0 auto;

  @media (max-width: 700px) {
    min-height: 400px;
  }
`;

// function interpolation(input: number[], output: number[]) {
//   return (value: number) => {
//     for (let x = 0; x < input.length - 1; x++) {
//       if (input[x] <= value && input[x + 1] >= value) {
//         const inputRange = [input[x], input[x + 1]];
//         const outputRange = [output[x], output[x + 1]];
//         const position =
//           (value - inputRange[0]) / Math.abs(inputRange[1] - inputRange[0]);

//         return outputRange[0] + (outputRange[1] - outputRange[0]) * position;
//       }
//     }
//   };
// }

function Tip(props: { tip: any }) {
  const { tip } = props;

  const viewport = useViewport();

  // Calculate desktop size once to decide whether to use animated blocks. If
  // the user resizes she might get the wrong version but both still work so
  // this is negligble.
  const useDesktopSize = React.useMemo(() => {
    return viewport !== undefined && viewport.width > 700;
  }, [viewport]);

  const ref = React.useRef<any>(null);
  // const value = useScrollReached(viewport === undefined ? undefined : ref);

  // const inCenter = !useDesktopSize
  //   ? true
  //   : value !== undefined
  //   ? value > -0.1 && value < 0.39
  //   : false;
  // const spring = useSpring({
  //   opacity: inCenter ? 1 : 0,
  //   y: inCenter ? 0 : value !== undefined && value < 0 ? 50 : -50,
  // });

  if (viewport === undefined) return null;

  return (
    <PageBlock
      height={viewport.height}
      css={css`
        display: flex;
        align-items: center;

        @media (max-width: 700px) {
          display: block;
        }
      `}
      // Only attach scroll behavior when exceeding breakpoint
      ref={useDesktopSize ? ref : undefined}
    >
      <ImagePane
        src={tip.photo}
        mobile={tip.photoMobile}
        center="center"
        css={css`
          @media (max-width: 700px) {
            position: relative;
            height: 300px;
            width: 100%;
          }
        `}
      />

      <TipBlock
        style={{
          WebkitMaskImage: useDesktopSize
            ? `url(${tip.decoration})`
            : undefined,
          paddingTop: useDesktopSize ? tip.headerCorrection : undefined,
          height: useDesktopSize ? tip.height : undefined,
        }}
        css={css`
          @media (max-width: 700px) {
            p {
              font-size: 16px;
              font-weight: 300;
              line-height: 26px;
            }
          }
        `}
      >
        <Knockout>{tip.title}</Knockout>
        <div>
          <img
            role="presentation"
            src={tip.decorationMobile}
            css={{
              position: 'absolute',
              bottom: '100%',
              left: '50%',
              transform: 'translateX(-50%)',

              display: 'none',
              width: 'calc(100vw - 70px)',

              '@media(max-width:700px)': {
                display: 'block',
              },
            }}
          />
          <p
            css={{
              position: 'relative',
              // isolation: 'isolate',
            }}
          >
            {tip.content}
          </p>
        </div>
      </TipBlock>
    </PageBlock>
  );
}

const SiteHead = styled.h1`
  font-size: 65px;
  letter-spacing: 20.03px;

  font-weight: 700;
  line-height: 78px;

  text-align: center;
  text-transform: uppercase;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 0 40px;

  @media (max-width: 700px) {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 15px;

    hyphens: auto;
    color: #c35a58;
  }
`;
