/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import * as React from 'react';
import { useViewport } from './useViewport';

interface ImageProps {
  src: string;
  mobile?: string;
  alt?: string;
  center?: string;
  children?: React.ReactNode;
  willItBlend?: boolean;
}
interface PaneProps {}

export function ImagePane(props: ImageProps & PaneProps) {
  return <CoolImage {...props} />;
}

export function CoolImage(props: ImageProps) {
  const { src, center, willItBlend, ...extra } = props;
  const viewport = useViewport();

  const url = viewport.width <= 700 && props.mobile ? props.mobile : props.src;

  return (
    <StickyImage
      style={{
        backgroundImage: `url(${url})`,
        backgroundPosition: props.center,
        mixBlendMode: props.willItBlend ? 'screen' : undefined,
      }}
      // @ts-ignore
      css={{
        '@media(max-width: 700px)': {
          mixBlendMode: 'initial !important',
        },
      }}
      {...extra}
    />
  );
}

export const StickyImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
`;
