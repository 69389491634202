import styled from '@emotion/styled';
import * as React from 'react';
import { animated } from 'react-spring';

export const TitleContent = styled.div`
  position: relative;
  background: #fff;

  article {
    position: relative;
    div {
      margin: 0 auto;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      max-width: 600px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 42px;
    color: #766b6b;
    letter-spacing: 4.14px;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    font-size: 18px;
    color: #4f4040;

    text-align: center;
    line-height: 35px;

    @media (max-width: 700px) {
      font-weight: 300;
    }
  }
`;

export const PageBlock = styled.div<{ height: number }>`
  position: relative;
  min-height: ${props => props.height}px;

  @media (max-width: 700px) {
    min-height: 0;
  }
`;

export const TipBlock = styled(animated.div)`
  position: relative;
  margin: 0 auto;
  max-width: 600px;

  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: 600px;
  background: rgba(255, 255, 255, 0.85);
  box-sizing: border-box;

  @media (max-width: 700px) {
    padding-top: 40px;
    position: relative;
    margin-top: 0;
    background: #fff;
  }

  p {
    position: relative;
    z-index: 2;
    margin: 0;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 60px;

    font-weight: 700;
    font-size: 14px;
    color: #4f4040;

    text-align: center;
    line-height: 23px;
  }
`;

const KnockoutBox = styled(animated.div)`
  margin: 0 auto;
  max-width: 600px;

  h2 {
    position: relative;
    z-index: 2;

    margin: 0;
    padding: 0 0 40px 0;
    font-weight: 800;
    letter-spacing: 4.14px;
    text-align: center;
    text-transform: uppercase;

    hyphens: auto;
    font-size: 36px;
    line-height: 45px;

    color: black;

    @media (max-width: 700px) {
      font-size: 26px;
      line-height: 36px;
      margin-top: -70px;
    }
  }
`;

export function Knockout(props: any) {
  return (
    <KnockoutBox>
      <h2>{props.children}</h2>
    </KnockoutBox>
  );
}

export const SweepstakesTitle = styled.h2`
  margin: 60px 0 10px 0;

  font-weight: 700;
  font-size: 42px;
  color: #494949;
  letter-spacing: 4.14px;
  text-transform: uppercase;
  text-align: center;
`;

export const SweepstakesSubtitle = styled.h3`
  margin: 0 0 60px 0;
  font-weight: 600;
  font-size: 18px;
  color: #494949;
  letter-spacing: 3.79px;
  text-transform: uppercase;
  text-align: center;
  line-height: 35px;
`;
